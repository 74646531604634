@font-face {
  font-family: "courier-prime-v1-latin-regular";
  src: url("./Fonts/courier-prime-v1-latin-regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

html, body {
  overflow-y: hidden;
  overflow-x: hidden;
  font-family: "courier-prime-v1-latin-regular";
}

body {
  margin: 0;
  font-size : 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-labeled {
    padding-top: 0;
    padding-bottom: 0;
}

.btn-labeled:focus {
    outline: 0;
    box-shadow: 0 0 0 0;
}

.cell-fit {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 1rem !important;
    width: 1px;
    white-space: nowrap;
    border: none;
}

.cell-fit-padding-tight {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0.5rem !important;
    width: 1px;
    white-space: nowrap;
    border: none;
}

.cell-fit-padding-wide {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 2rem !important;
    width: 1px;
    white-space: nowrap;
    border: none;
}

.padding-wide {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 2rem !important;
}

.cell-fit-padding-very-wide {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 3rem !important;
    width: 1px;
    white-space: nowrap;
    border: none;
}

.cell-fit-no-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 1px;
    white-space: nowrap;
    border: none;
}

.cell-truncate {
    max-width: 1px;
    text-overflow: ellipsis;
    overflow: hidden;
    border: none;
}

/* stylings for cards */
.cell-fit-card {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    width: 1px;
    white-space: nowrap;
    border: none;
}

.padding-horizontal {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.padding-horizontal-wide {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}

.padding-small {
    padding-left: 0px !important;
    padding-right: 1rem !important;
}

.no-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.padding-medium {
    padding-left: 0px !important;
    padding-right: 1.5rem !important;
}

.padding-large {
    padding-left: 0px !important;
    padding-right: 2rem !important;
}

.no-border {
    border-top: none !important;
    border-bottom: none !important;
}

.row-card {
    border-radius: 0.5rem !important;
    box-shadow: 0rem .5rem 1rem rgba(0,0,0,.15) !important;
    border: 1px solid rgba(0,0,0,.125);
    background-clip: border-box;
}

.custom-tooltip {
    margin: 0px;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
    white-space: nowrap;
}

.tooltip-inner {
    background-color: rgb(11, 177, 165);
    color: white;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: rgb(11, 177, 165);
}

.navbar-color {
    background-color: #12243a;
}

.mr-sm {
    margin-right: "1rem";
}

.th-fixed {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #ffffff;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.2);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(0,0,0,0.3);
    border-radius: 10px;
}

.flex-container {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.img-pixels {
    width: 100%;
    image-rendering: pixelated;
}
